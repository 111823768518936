<template>
  <div ref="wrapper" class="tel-input">
    <Label v-if="label" :for="id || name" :required="required">{{ label }}</Label>
    <VueTelInput
      :modelValue="modelValue || ''"
      :disabled="disabled"
      :required="required"
      :defaultCountry="defaultCountry"
      :autoDefaultCountry="autoDefaultCountry"
      :onlyCountries="onlyCountries"
      validCharactersOnly
      :autoFormat="false"
      :inputOptions="{
        name: name,
        id: id,
        placeholder: '',
        autocomplete: 'off',
      }"
      :dropdownOptions="{
        showDialCodeInList: false,
        showFlags: true,
        showSearchBox: false,
        width,
      }"
      @onInput="(number) => emit(number)"
      @countryChanged="handleCountryChange"
    >
      <template #arrow-icon>
        <span class="ml-1 text-gray-400">
          <Svgicon name="outline-selector" class="h-5 w-5" />
        </span>
      </template>
    </VueTelInput>
    <slot name="after" />
    <BaseErrorList :errors="errors" />
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import Label from "~/components/inputs/Label";

export default {
  components: {
    Label,
    VueTelInput,
  },

  props: {
    disabled: Boolean,
    required: Boolean,
    modelValue: { type: String, default: "" },
    defaultCountry: {
      type: String,
      default: "DK",
    },

    autoDefaultCountry: {
      type: Boolean,
      default: true,
    },

    onlyCountries: {
      type: Array,
      default: () => ["DK", "DE", "SE", "NO"],
    },

    label: String,
    name: String,
    id: String,
    errors: { type: Array, default: () => [] },
    type: {
      type: String,
      default: "tel",
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      width: null,
      dialCode: "45",
    };
  },

  mounted() {
    this.width = `${this.$refs.wrapper.clientWidth}px`;
  },

  methods: {
    handleCountryChange(payload) {
      this.dialCode = payload.dialCode;
      this.emit(this.modelValue);
    },

    emit(number) {
      let result = number;

      if (!result) {
        this.$emit("update:modelValue", result);
        return;
      }

      if (!result.startsWith("+") || result.length > 2) {
        result = `+${this.dialCode}${result.replace(/\+\d{2}/, "")}`;
      }

      this.$emit("update:modelValue", result.replace(/\s/g, ""));
    },
  },
};
</script>

<style>
.tel-input .vue-tel-input {
  @apply w-full rounded-md border border-gray-300 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600;
}

.tel-input .vti__dropdown {
  @apply rounded-l-md border-r border-gray-300 px-3;
}

.tel-input .vti__input {
  @apply h-10 rounded-none rounded-r-md border-none pl-3 text-sm leading-10 outline-none focus:border-none focus:outline-none focus:ring-0 disabled:bg-gray-50;
}

.tel-input .vti__flag {
  @apply m-0 mr-2;
}

.tel-input .vti__dropdown-list {
  @apply m-0 min-w-56 py-1;
}

.tel-input .vti__dropdown-list.below {
  @apply py-0;
}

.tel-input .vti__dropdown-item {
  @apply flex items-center px-4 py-2;
}

.tel-input .vti__dropdown-item strong {
  @apply text-sm font-normal;
}

.tel-input .vti__dropdown-item .vti__flag-wrapper {
  @apply mr-3 flex w-auto;
}

.tel-input .vti__dropdown-list.below {
  @apply top-full mt-2 rounded-md border-gray-200 shadow-lg;
}
</style>
