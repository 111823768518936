<template>
  <div>
    <div v-if="withInput" class="relative flex flex-col justify-center text-gray-500">
      <input
        :value="model"
        type="date"
        :name="name"
        :min="toString(min)"
        :max="toString(max)"
        class="relative h-8 w-full rounded-md border border-gray-300 px-2 py-0 text-sm tabular-nums leading-8 text-black shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
        @input="$event.target.validity.valid && (model = $event.target.value)"
      />
      <svgicon name="outline-calendar" class="absolute right-3 h-7 w-5 bg-white" :fill="false" />
    </div>
    <VueCal
      v-model:activeView="activeView"
      class="date-picker mt-2"
      xsmall
      hideViewSelector
      :dblclickToNavigate="false"
      :disableViews="['week', 'day']"
      :time="false"
      :transitions="false"
      locale="da"
      :selectedDate="toDate(model)"
      :minDate="toDate(min)"
      :maxDate="toDate(max)"
      showWeekNumbers
      @cellClick="handleClick"
    >
      <template #arrow-prev>
        <svgicon ref="prevMonth" name="solid-chevron-left" class="h-4 w-4 text-gray-900" />
      </template>
      <template #arrow-next>
        <svgicon ref="nextMonth" name="solid-chevron-right" class="h-4 w-4 text-gray-900" />
      </template>
    </VueCal>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { dayjs } from "~/utils/dayjs";

export default {
  components: { VueCal },

  props: {
    modelValue: {
      type: [Date, String],
      default: null,
    },

    min: {
      type: [Date, String],
      default: null,
    },

    max: {
      type: [Date, String],
      default: dayjs().add(10, "year").startOf("day").toDate(),
    },

    withInput: Boolean,
    name: String,
  },

  emits: ["update:modelValue", "hide"],

  data() {
    return {
      activeView: "month",
    };
  },

  computed: {
    model: {
      get() {
        return this.toString(this.modelValue);
      },

      set(input) {
        if (!input) {
          return;
        }

        const emitString = typeof this.modelValue === "string";

        if (emitString) {
          input = this.toString(input);
        } else {
          input = this.toDate(input);
        }

        this.$emit("update:modelValue", input);
      },
    },
  },

  methods: {
    handleClick(event) {
      const currentDate = this.model || undefined;

      if (this.activeView === "years") {
        const year = dayjs(event).year();
        this.model = dayjs(currentDate).year(year).toDate();

        this.setView("year");
      } else if (this.activeView === "year") {
        const month = dayjs(event).month();
        this.model = dayjs(currentDate).month(month).toDate();

        this.setView("month");
      } else if (this.activeView === "month") {
        this.model = event;

        this.$emit("hide");
      }
    },

    setView(view) {
      setTimeout(() => {
        this.activeView = view;
      }, 1);
    },

    toDate(input) {
      if (!input) {
        return null;
      }

      // Strip time and timezone from date before parsing it.
      return new Date(this.toString(input));
    },

    toString(input) {
      if (!input) {
        input = "";
      }

      // Use dayjs to format the date to a string in its current timezone.
      // This is necessary because VueCal emits dates with inconsistent times making the timezone conversion of Date methods like toISOString incorrect in some cases.
      if (input instanceof Date) {
        input = dayjs(input).format("YYYY-MM-DD");
      }

      return input.split("T")[0];
    },
  },
};
</script>

<style lang="postcss">
@import "vue-cal/dist/vuecal.css";
input::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}

.vuecal.date-picker {
  @apply aspect-1 w-60 flex-grow-0 text-sm text-gray-700 shadow-none;
}

.vuecal.date-picker .weekday-label {
  @apply text-xs text-gray-400;
}

/* .date-input__date-picker .vuecal__title {
  @apply hidden h-5;
} */

.vuecal.date-picker .vuecal__title span:nth-child(2) {
  @apply hidden;
}

.vuecal.date-picker .vuecal__cell {
  @apply aspect-1 cursor-pointer bg-white hover:bg-gray-100;
}

.vuecal.date-picker .vuecal__cell.vuecal__cell--out-of-scope {
  @apply bg-gray-50 text-gray-400 hover:bg-gray-100;
}

.vuecal.date-picker .vuecal__cell.vuecal__cell--selected {
  @apply bg-blue-600 text-white;
}

.vuecal.date-picker .vuecal__cell:before {
  @apply border;
}

.vuecal.date-picker .vuecal__cell:first-child::before {
  @apply rounded-tl-lg;
}

.vuecal.date-picker .vuecal__cell:nth-child(7)::before {
  @apply rounded-tr-lg;
}
.vuecal.date-picker .vuecal__cell:nth-child(36)::before {
  @apply rounded-bl-lg;
}

.vuecal.date-picker .vuecal__cell:last-child::before {
  @apply rounded-br-lg;
}

.vuecal.date-picker .vuecal__weekdays-headings {
  @apply border-none text-gray-500;
}

.vuecal.date-picker .vuecal__title-bar {
  @apply bg-transparent text-sm font-semibold;
}

.vuecal.date-picker .vuecal__body,
.vuecal.date-picker .vuecal__bg {
  @apply overflow-visible;
}

.vuecal.date-picker .vuecal__cells {
  @apply h-full rounded-lg shadow-sm;
}

.vuecal.date-picker .vuecal__arrow {
  @apply m-0 mx-3 flex h-4 w-4 items-center;
}
</style>
