<script setup>
import { computed, inject, useAttrs } from "vue";
import { cva } from "class-variance-authority";
import { Primitive } from "radix-vue";
import { cn } from "~/utils/cn";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  as: {
    type: String,
    default: "div",
  },
});

const look = computed(() => props.as);
const { size } = inject("INPUT_CONTEXT", { size: "md" });
const variants = cva("-my-px flex items-center border-gray-300 text-gray-700", {
  variants: {
    size: {
      sm: "px-2 py-0 leading-6",
      md: "px-2 py-0 leading-8",
      lg: "px-3 py-0 leading-10",
    },
    look: {
      button: "bg-gray-50 first:border-r last:border-l",
    },
  },
  defaultVariants: {
    size: "md",
  },
});
const { class: klass, ...attrs } = useAttrs();
</script>

<template>
  <Primitive :as="as" :class="cn(variants({ size, look }), klass)" :type="look === 'button' ? 'button' : false" v-bind="attrs">
    <slot />
  </Primitive>
</template>
