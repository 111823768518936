const optionifyObject = (object) => {
  const values = Object.keys(object);
  return values.map((value) => ({ label: object[value], value }));
};

export const residenceStatusObject = {
  living_at_home: "Hjemmeboende",
  away_from_home: "Udeboende",
};

export const residenceStatusOptions = optionifyObject(residenceStatusObject);

export const dependentDutyObject = {
  none: "Ingen",
  single: "Enlig",
  double: "Almindelig",
};

export const dependentDutyOptions = optionifyObject(dependentDutyObject);

export const taxCardObject = {
  primary: "Hovedkort",
  secondary: "Bikort",
  gross: "Bruttokort",
  free: "Frikort",
  no_tax_info: "Intet skattekort",
};

export const notificationTitleObject = {
  "Notes::CreatedNotifier::Notification": "Nyt notat",
  "QuestionnaireCompletions::CreatedQuestionnaireCompletionNotifier::Notification": "Ny samtale",
  "StayStayTypes::MaxDaysOnSchoolPeriodExceededNotifier::Notification": "Tilladt tid på skoleperiode overskredet",
  "StayStayTypes::SchoolPeriodEndsSoonNotifier::Notification": "Tilladt tid på skoleperiode udløber",
  "Students::BirthdayReminderNotifier::Notification": "Fødselsdagspåmindelse",
  "Students::IllegalAbsenceNotifier::Notification": "Fravær",
};

export const examRegistrationStatusObject = {
  registered: "Tilmeldt",
  deregistered: "Afmeldt",
  exempt: "Fritaget",
  absent: "Ikke fremmødt",
  covid19: "COVID-19",
};

export const drawStatusObject = {
  unknown: "Ukendt",
  is_drawn: "Udtrukket",
  not_drawn: "Ikke udtrukket",
};

export const meritStatus = {
  applied: "Ansøgt",
  approved: "Godkendt",
  rejected: "Afvist",
};

export const meritGradeTypes = {
  exam: "Prøvekarakter",
  teacher: "Standpunktskarakter",
};

export const claimants = [
  "AAKA",
  "AAKF",
  "AAKR",
  "AAST",
  "AFLE",
  "AMAA",
  "AMJA",
  "AMOR",
  "AOUY",
  "AS6L",
  "AUAP",
  "FLED",
  "KOMA",
  "KOMF",
  "KOMR",
  "KOYD",
  "MAAS",
  "MJAF",
  "MORY",
  "NETI",
  "SJ6L",
  "SJOB",
  "UAAP",
  "UVM",
].sort();

export const educationTypes = [
  "Grundskole",
  "10. klasse",
  "eud",
  "avu",
  "euv",
  "fgu",
  "aing",
  "hfu",
  "hfm",
  "hfeu",
  "hfem",
  "hfeb",
  "hfb",
  "hfe",
  "stx",
  "hf",
  "hhx",
  "htx",
  "gsk",
  "eux",
  "gif",
  "meux",
  "beux",
  "andet",
].sort();

export const educationTypeOptions = educationTypes.map((value) => ({ label: value, value }));

export const claimantOptions = claimants.map((value) => ({ label: value, value }));
export const defaultClaimant = "UVM";

export const absenceOptions = [
  [7, "7 dage"],
  [14, "14 dage"],
  [30, "30 dage"],
  [90, "90 dage"],
  [null, "Total"],
];

export const defaultAbsenceValue = 30;

export const screeningStatusObject = {
  signed_up: "Ansøgt",
  in_progress: "Igangværende",
  completed: "Gennemført",
};

export const screeningStatusOptions = Object.keys(screeningStatusObject).map((key) => ({
  label: screeningStatusObject[key],
  value: key,
}));

export const gradesOptions = [
  { label: "Bestået", value: "is_passed" },
  { label: "Ikke bestået", value: "not_passed" },
  { label: "12", value: "12" },
  { label: "10", value: "10" },
  { label: "7", value: "7" },
  { label: "4", value: "4" },
  { label: "02", value: "02" },
  { label: "00", value: "00" },
  { label: "-3", value: "-3" },
];

export const simpleGradesOptions = [
  { label: "12", value: 12 },
  { label: "10", value: 10 },
  { label: "7", value: 7 },
  { label: "4", value: 4 },
  { label: "02", value: 2 },
  { label: "00", value: 0 },
  { label: "-3", value: -3 },
];

export const taxonomyStatusOptions = [
  { label: "Revisorgodkendt", value: "signed_by_accountant" },
  { label: "Udfyldt af institution", value: "modified_by_institution" },
  { label: "UngeDb: OK", value: "success_unge_db" },
  { label: "UngeDb: Ukendt", value: "unknown_unge_db" },
];

export const UngedatabasenStatuses = ["Ukendt", "Positiv udslusning", "Ikke opfyldt", "Afventer 4 måneder", "Afventer SKAT / SU kontor", "Data om FGU forløb inkomplet"];

export const payDeductionOptions = [
  { label: "Fast beløb i lønkørsel", value: "fixed_per_wage_period" },
  { label: "Følg skoledagskalender", value: "follow_school_day_calendar" },
];

export const shortMessageStatusOptions = [
  { label: "Leveret", value: "1" },
  { label: "Fejlet", value: "2" },
  { label: "I kø", value: "4" },
  { label: "Ikke leveret", value: "8" },
];

export const deprecatedShortMessageStatusOptions = [
  { label: "Afsendt", value: "sent" },
  { label: "Ingen", value: "" },
  { label: "Planlagt", value: "scheduled" },
  { label: "I kø", value: "queued" },
  { label: "Leveret", value: "delivered" },
  { label: "Ikke leveret", value: "undelivered" },
  { label: "Fejlet", value: "failed" },
];

export const manageStudentPageActivitiesOptions = [
  { value: 0, label: "Alle" },
  { value: 1, label: "Mig" },
];

export const permissionOptions = [
  { value: 0, label: "Ingen" },
  { value: 3, label: "Mine elever" },
  { value: 1, label: "På min afdeling" },
  { value: 2, label: "Alle" },
];

export const sensitiveDataOptions = [
  { value: 0, label: "Ingen" },
  { value: 1, label: "Se" },
  { value: 2, label: "Se og redigere" },
];

export const poolExtensionsOptions = [
  { value: 0, label: "Ingen" },
  { value: 1, label: "Ansøge" },
  { value: 2, label: "Godkende" },
  { value: 3, label: "Ansøge og godkende" },
];

export const updateStudentNotesOptions = [
  { value: 0, label: "Ingen" },
  { value: 1, label: "Egne" },
  { value: 2, label: "Alle" },
];

export const courseLevelOptions = [
  { value: "I", label: "I" },
  { value: "G", label: "G" },
  { value: "E", label: "E" },
  { value: "D", label: "D" },
];

export const curriculumLevelOptions = courseLevelOptions;

export const workshopLevelOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const worksExperienceProbationPeriodOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
];

export const initialWorkshopLevelOptions = [{ value: "I", label: "I" }, ...workshopLevelOptions];

export const inactiveStaysFilterOptions = [
  {
    label: "Aktive",
    value: "CURRENT",
  },
  {
    label: "Afsluttede",
    value: "PREVIOUS",
  },
  {
    label: "Kommende",
    value: "FUTURE",
  },
];

export const inactiveFilterOptions = [
  {
    label: "Vis kun aktive",
    value: "active",
  },
  {
    label: "Vis alle",
    value: "all",
  },
];

export const CURRICULUM_GRADES_OPTIONS = [
  { value: "-3", label: "-3" },
  { value: "00", label: "00" },
  { value: "02", label: "02" },
  { value: "4", label: "4" },
  { value: "7", label: "7" },
  { value: "10", label: "10" },
  { value: "12", label: "12" },
];

export const WORKSHOP_GRADES_OPTIONS = [
  { label: "Bestået", value: "is_passed" },
  { label: "Ikke bestået", value: "not_passed" },
];

// ⚠️if you add something there
// ⚠️make sure to add it to the backend as well
// ⚠️in config/locales/da.yml
export const noteTagsOptions = [
  { value: "termination", label: "Afbrydelse af FGU", color: "red" },
  { value: "absence", label: "Fraværsnotat", color: "orange" },
  { value: "enrollment", label: "Indskrivningsnotat", color: "green" },
  { value: "kui", label: "KUI-notat", color: "teal" },
  { value: "teacher", label: "Lærernotat", color: "violet" },
  { value: "school_payment", label: "Skoleydelse", color: "yellow" },
  { value: "counselor", label: "Vejledernotat", color: "indigo" },
  { value: "dyslexia", label: "Ordblinde/SPS", color: "emerald" },
  { value: "other", label: "Øvrigt", color: "fuchsia" },
  { value: "none", label: "Uden kategori", color: "gray" },
];

export const noteTypeOptions = [
  { value: "examregistration", label: "Prøvenotat", color: "rose" },
  { value: "stay", label: "Opholdsnotat", color: "purple" },
  { value: "student", label: "Elevnotat", color: "blue" },
  { value: "stayevent", label: "Registreringsnotat", color: "pink" },
  { value: "stayreducedpercentage", label: "Nedsat tid-notat", color: "lime" },
  { value: "mentorpersonstay", label: "Mentormøde", color: "rose" },
  { value: "user", label: "Notat", color: "blue" },
];

export const combinedNoteOptions = noteTagsOptions.concat(noteTypeOptions);

export const colors = [
  "slate",
  "gray",
  "zinc",
  "neutral",
  "stone",
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
];
