<template>
  <div class="pointer-events-auto relative w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5" data-testid="toast">
    <div v-if="type === 'async'" class="absolute left-0 top-0 h-1 w-full bg-gray-100">
      <div class="anim-running-progress h-1 bg-blue-500" />
    </div>
    <div v-else-if="duration > 0" class="absolute bottom-0 left-0 h-1 w-full">
      <div ref="progressBar" class="anim-duration-progress h-full bg-blue-500" />
    </div>
    <div class="p-4">
      <div class="flex items-start">
        <div
          class="flex-shrink-0"
          :class="[['info', 'async'].includes(type) && 'text-gray-400', type === 'success' && 'text-green-400', type === 'error' && 'text-red-500']"
        >
          <svgicon v-if="['info', 'async'].includes(type)" name="outline-information-circle" class="h-6 w-6" :fill="false" />
          <svgicon v-if="type === 'success'" name="outline-check-circle" class="h-6 w-6" :fill="false" />
          <svgicon v-if="type === 'error'" name="outline-exclamation-circle" class="h-6 w-6" :fill="false" />
        </div>
        <div class="ml-3 w-0 flex-1">
          <p class="my-1 text-sm font-medium text-gray-900">
            {{ title }}
          </p>
          <p class="text-sm text-gray-500">
            {{ text }}
          </p>

          <div v-if="data && data.version" class="mt-3 text-xs text-gray-400">
            Version:
            {{ data.version }}
          </div>

          <div v-if="data && data.actions" class="mt-3 flex space-x-7">
            <button
              v-for="(action, index) in data.actions"
              :key="index"
              class="rounded-md bg-white text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              :class="[action.primary ? 'text-blue-600 hover:text-blue-500' : 'text-gray-700 hover:text-gray-500']"
              @click="action.click"
            >
              {{ action.text }}
            </button>
          </div>
        </div>

        <div class="my-1 ml-4 flex flex-shrink-0">
          <BaseIconButton
            icon="solid-x"
            class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            title="Luk"
            @click="$emit('close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    text: String,
    data: Object,
    length: Number,
  },

  emits: ["close"],

  computed: {
    duration() {
      return Math.max(this.length - 600, 0);
    },

    durationInSeconds() {
      return `${this.duration / 1000}s`;
    },
  },

  mounted() {
    if (this.$refs.progressBar) {
      this.$refs.progressBar.style.animationDuration = this.durationInSeconds;
    }
  },
};
</script>

<style lang="scss">
@keyframes running-progress {
  0% {
    margin-left: -100%;
    width: 50%;
  }
  50% {
    margin-left: 0%;
    width: 50%;
  }
  100% {
    margin-left: 100%;
    width: 25%;
  }
}

@keyframes duration-progress {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.anim-duration-progress {
  animation-name: duration-progress;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-delay: 600ms;
}

.anim-running-progress {
  animation-name: running-progress;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
