<template>
  <div ref="root">
    <span v-if="label" :class="!label && 'flex items-center'">
      <Label v-if="label" :for="idOrName" :required="required">{{ label }}</Label>
      <BaseSpinner v-if="loading" class="mb-1 ml-2" size="sm" />
    </span>
    <Tippy
      ref="tippy"
      :options="{
        placement: 'bottom-start',
        arrow: false,
        trigger: 'manual',
        maxWidth: 'none',
        hideOnClick: false,
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
        },
      }"
      preventBlurOnMouseDown
      @show="isShowing = true"
      @hide="isShowing = false"
    >
      <template #trigger="{ controlId }">
        <div
          class="relative flex items-center overflow-hidden rounded-md border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
          :class="active ? '!border-blue-500' : ''"
          data-testid="select-trigger"
          @keydown.down.prevent="highlightNextOption($event)"
          @keydown.up.prevent="highlightPreviousOption($event)"
        >
          <input
            :id="idOrName"
            ref="input"
            v-model="model"
            :required="!hasNullValue && required"
            tabindex="-1"
            class="absolute inset-0 border-none focus:outline-none"
            type="text"
            aria-hidden="true"
            :name="nameOrId"
            :disabled="isDisabled"
            @keydown.enter="toggleOptionsAndFocusTrigger"
            @keydown.space="toggleOptionsAndFocusTrigger"
          />

          <div v-if="withSearch" class="relative w-full">
            <input
              ref="trigger"
              class="w-full cursor-default border-none bg-white text-left placeholder:text-gray-900 focus:outline-none focus:placeholder:text-gray-400"
              :class="[
                size === 'sm' && 'h-6 p-0 pl-2 pr-10 text-sm leading-6',
                size === 'md' && 'h-8 p-0 pl-2 text-sm leading-8',
                size === 'lg' && 'h-10 pl-3 text-sm leading-10',
              ]"
              type="text"
              :placeholder="placeholder"
              aria-haspopup="listbox"
              :aria-expanded="isShowing"
              :aria-controls="controlId"
              data-testid="trigger"
              :value="query"
              @blur="closeOptions"
              @click="toggleOptions"
              @keydown.enter="handleEnterKeyPress"
              @keydown.esc="closeOptions"
              @input="handleQueryChange"
            />
            <span class="absolute inset-y-0 right-0 flex items-center text-gray-900" :class="[size === 'sm' ? 'pr-1' : 'pr-2.5']">
              <button v-if="selected" type="button" tabindex="-1" @click="clearOption">
                <svgicon name="outline-x" :fill="false" class="h-5 w-5" />
              </button>
              <span v-else class="pointer-events-none">
                <svgicon name="outline-selector" :fill="false" class="h-5 w-5" />
              </span>
            </span>
          </div>

          <button
            v-else
            ref="trigger"
            type="button"
            class="relative -m-px min-w-full bg-white pr-16 text-left focus:outline-none disabled:bg-gray-50 disabled:text-gray-400 sm:text-sm"
            :class="[
              size === 'sm' && 'h-6 p-0 pl-2 pr-10 text-sm leading-6',
              size === 'md' && 'h-8 p-0 pl-2 pr-8 text-sm leading-8',
              size === 'lg' && 'h-10 pl-3 pr-10 text-sm leading-10',
            ]"
            aria-haspopup="listbox"
            :aria-expanded="isShowing"
            :aria-controls="controlId"
            :disabled="isDisabled"
            data-testid="trigger"
            @blur="closeOptions"
            @click="toggleOptions"
            @keydown="handleFindOption"
            @keydown.enter="handleEnterKeyPress"
            @keydown.esc="closeOptions"
          >
            <slot v-if="selected" name="selected" :option="selected">
              <span class="block truncate font-normal" :class="[fitContent ? 'w-fit' : 'w-full', disabled ? 'text-gray-400' : 'text-gray-900']">
                {{ selected.label }}
              </span>
            </slot>
            <span v-else class="block w-full truncate text-gray-900"> {{ placeholder }} </span>
            <span class="absolute inset-y-0 right-0 flex items-center text-gray-900" :class="[size === 'lg' ? 'pr-2.5' : 'pr-1']">
              <span class="pointer-events-none">
                <svgicon name="outline-selector" :fill="false" class="h-5 w-5" />
              </span>
            </span>
          </button>
          <BaseIconButton
            v-if="!required && !disabled && selected && !withSearch"
            icon="outline-x"
            class="absolute right-0 z-50 mr-8 text-gray-700"
            data-testid="select-clear-button"
            tabindex="-1"
            @click.stop="clearOption"
          />
        </div>
      </template>
      <template #content="{ controlId }">
        <ul
          :id="controlId"
          ref="listbox"
          class="p-1 text-sm"
          tabindex="-1"
          role="listbox"
          :aria-activedescendant="idOrName + 'Option' + highlightedOption?.value"
          :style="{ minWidth: maxWidth + 'px' }"
          @mouseleave="highlightedOption = null"
          @mousedown.prevent
        >
          <li
            v-if="emptyText && (!flatOptions || flatOptions.length === 0)"
            class="relative cursor-default select-none p-2 text-center text-xs text-gray-400"
            aria-disabled="false"
          >
            {{ emptyText }}
          </li>
          <li
            v-for="option in flatOptions"
            :id="!option.group && idOrName + 'Option' + option.value"
            :key="option.value"
            ref="option"
            class="relative cursor-pointer select-none rounded py-2 pl-3 pr-12"
            :class="{
              'bg-blue-600 text-white': highlightedOption?.value === option.value,
              'bg-gray-200 text-gray-800': option.disabled && highlightedOption?.value === option.value,
              'text-gray-500': option.group,
              'text-gray-900': !option.group,
              'cursor-default text-gray-200': option.disabled,
            }"
            :role="!option.group && 'option'"
            :aria-selected="!option.group && (model === option.value).toString()"
            :aria-disabled="option.disabled === true ? 'true' : 'false'"
            @mousemove="handleMouseHoverOption($event, option)"
            @click="($event) => !option.group && !option.disabled && selectOption($event, option)"
          >
            <slot name="option" :option="option">
              <span
                class="block truncate"
                :class="{
                  'text-xs font-medium': option.group,
                  'cursor-default text-gray-200': option.disabled,
                  'cursor-default text-gray-400': option.disabled && highlightedOption?.value === option.value,
                }"
              >
                {{ option.label }}
              </span>
            </slot>
            <span
              v-show="model === option.value"
              class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600"
              :class="{
                'text-white': highlightedOption?.value === option.value,
              }"
            >
              <svgicon name="solid-check" class="h-5 w-5" />
            </span>
          </li>
        </ul>
      </template>
    </Tippy>
    <BaseErrorList :errors="errors" />
  </div>
</template>

<script>
import FormInput from "~/components/form/FormInput";
import Label from "~/components/inputs/Label";
import Tippy from "~/components/tippy/Tippy";

let resetQueryTimeout = null;

export default {
  name: "Select",

  components: { Tippy, Label },

  extends: FormInput,

  props: {
    active: Boolean,
    label: String,
    id: String,
    name: String,
    required: Boolean,
    loading: Boolean,
    disabled: Boolean,
    tabindex: Number,
    disableAutoSelect: Boolean,
    emptyText: {
      type: String,
      default: "Ingen valgmuligheder",
    },

    autofocus: Boolean,
    withSearch: Boolean,
    // we can do groups by passing an array as the value like { label: 'My group', value: [...someOptions] }
    options: {
      type: Array,
      required: true,
    },

    errors: Array,
    placeholder: {
      type: String,
      default: "Vælg",
    },

    size: {
      type: String,
      default: "lg",
      validator(value) {
        return ["sm", "md", "lg"].includes(value);
      },
    },

    fitContent: Boolean,
  },

  emits: ["keydown", "search"],

  compatConfig: { COMPONENT_V_MODEL: false },

  data() {
    return {
      isShowing: false,
      maxWidth: 0,
      highlightedOption: null,
      query: "",
    };
  },

  computed: {
    isDisabled() {
      return this.disabled || this.loading;
    },

    // options flattened to a single level with groups headings
    flatOptions() {
      return this.options.reduce((acc, option) => {
        if (Array.isArray(option.value)) {
          return [...acc, { label: option.label, group: true }, ...option.value];
        }

        return [...acc, option];
      }, []);
    },

    // same as flatOptions but only selectable options (no grooup headings)
    justOptions() {
      return this.flatOptions.filter((option) => !option.group);
    },

    selected() {
      return this.justOptions.find((option) => option.value === this.model);
    },

    idOrName() {
      return this.id || this.name;
    },

    nameOrId() {
      return this.name || this.id;
    },

    hasNullValue() {
      return this.justOptions.some((option) => option.value == null);
    },
  },

  watch: {
    model() {
      this.selectFirstOptionIfEmptyAndRequired();
      this.updateValidity();
      this.query = this.selected?.label || "";
    },

    options: {
      deep: true,
      handler() {
        this.selectFirstOptionIfEmptyAndRequired();
        this.updateValidity();
      },
    },

    selected() {
      this.setDefaultHighlightedOption();
    },

    isShowing() {
      this.setDefaultHighlightedOption();

      this.scrollToHighlightedOption();
    },

    query() {
      // TODO: this is starting to diverge with both finding and search/autocomplete. We should make a new one with just autocomplete.
      if (!this.withSearch && this.query) {
        this.highlightedOption = this.justOptions.find((option) => option.label.toLowerCase().indexOf(this.query.toLowerCase()) === 0);

        this.scrollToHighlightedOption();

        clearTimeout(resetQueryTimeout);

        resetQueryTimeout = setTimeout(() => {
          this.query = "";
        }, 500);
      }
    },
  },

  mounted() {
    this.maxWidth = this.$refs.root.offsetWidth;

    this.selectFirstOptionIfEmptyAndRequired();
    this.updateValidity();

    if (this.autofocus) {
      this.$refs.trigger.focus();
    }
  },

  methods: {
    clearOption() {
      this.model = null;
    },

    selectOption(event, option) {
      this.model = option.value;

      if (event) {
        this.closeOptions(event);
      }
    },

    selectHighlightedOption(event) {
      if (this.isShowing && this.highlightedOption) {
        this.selectOption(event, this.highlightedOption);
        this.closeOptions(event);
      }
    },

    selectFirstOptionIfEmptyAndRequired() {
      if (this.withSearch || this.disableAutoSelect) {
        return;
      }

      if (this.required && this.justOptions.length === 1) {
        this.selectOption(null, this.justOptions[0]);
      }
    },

    handleEnterKeyPress(event) {
      event.preventDefault();

      if (this.isShowing && this.highlightedOption) {
        this.selectHighlightedOption(event);
      } else {
        this.toggleOptions(event);
      }
    },

    handleMouseHoverOption(event, option) {
      const mouseMoved = event.movementX !== 0 || event.movementY !== 0;

      // Only update highlighted option if mouse actually moved. If it didn't
      // move the event is probably fired because a new option was moved
      // underneath the cursor by stepping through options with the arrow keys.
      if (!option.group && mouseMoved) {
        this.highlightedOption = option;
      }
    },

    handleFindOption(event) {
      if (event.key.length === 1) {
        this.query += event.key;
      }
    },

    toggleOptions(event) {
      if (this.isShowing) {
        this.closeOptions(event);
      } else {
        this.$refs.tippy.show();
      }
    },

    closeOptions(event) {
      if (!this.isShowing) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      this.$refs.tippy.hide();
      this.highlightedOption = null;

      if (this.withSearch) {
        this.query = this.selected?.label || "";
      }
    },

    toggleOptionsAndFocusTrigger(event) {
      this.$refs.trigger.focus();
      this.toggleOptions(event);
      event.preventDefault();
    },

    highlightNextOption($event) {
      if (!this.isShowing) {
        this.$emit("keydown", $event);
        return;
      }

      if (!this.highlightedOption) {
        const [option] = this.justOptions;
        this.highlightedOption = option;

        this.scrollToHighlightedOption();
      } else {
        const index = this.justOptions.findIndex((option) => option.value === this.highlightedOption.value);

        if (index < this.justOptions.length - 1) {
          this.highlightedOption = this.justOptions[index + 1];

          this.scrollToHighlightedOption();
        }
      }
    },

    highlightPreviousOption($event) {
      if (!this.isShowing) {
        this.$emit("keydown", $event);
        return;
      }

      if (this.highlightedOption) {
        const index = this.justOptions.findIndex((option) => option.value === this.highlightedOption.value);

        if (index > 0) {
          this.highlightedOption = this.justOptions[index - 1];
        }
      } else {
        this.highlightedOption = this.justOptions[this.justOptions.length - 1];
      }

      this.scrollToHighlightedOption();
    },

    handleQueryChange(event) {
      const { value } = event.target;
      this.query = value;
      this.$emit("search", value);

      if (!this.isShowing) {
        this.$refs.tippy.show();
      }
    },

    updateValidity() {
      if (!this.$refs.input) {
        return;
      }

      if (this.required && !this.selected) {
        this.$refs.input.setCustomValidity("Vælg et punkt på listen.");
      } else {
        this.$refs.input.setCustomValidity("");
      }
    },

    setDefaultHighlightedOption() {
      if (this.selected && !this.highlightedOption) {
        this.highlightedOption = this.selected;
      }
    },

    getHighlightedElement() {
      if (!this.highlightedOption) {
        return null;
      }

      return this.getOptionElement(this.highlightedOption);
    },

    getOptionElement(option) {
      return this.$refs.option?.find((optionElement) => optionElement.id === `${this.idOrName}Option${option.value}`);
    },

    scrollToHighlightedOption() {
      if (this.isShowing && this.highlightedOption) {
        requestAnimationFrame(() => {
          this.getHighlightedElement()?.scrollIntoView({
            block: "nearest",
          });
        });
      }
    },
  },
};
</script>
