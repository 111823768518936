<template>
  <div v-if="value !== null || $slots.default">
    <BaseDetailLabel>{{ label }}</BaseDetailLabel>
    <slot name="default">
      <BaseDetailValue>{{ value }}</BaseDetailValue>
    </slot>
  </div>
</template>

<script>
import BaseDetailLabel from "~/components/base/Detail/BaseDetailLabel";
import BaseDetailValue from "~/components/base/Detail/BaseDetailValue";

export default {
  components: {
    BaseDetailLabel,
    BaseDetailValue,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>
