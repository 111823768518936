<script>
export const registerPanelInjectKey = Symbol("registerPanel");
export const actionsPortalInjectKey = Symbol("actionsPortal");
</script>

<script setup>
import { nextTick, provide, ref, watch } from "vue";
import { nanoid } from "nanoid";
import { PortalTarget } from "portal-vue";
import { TabsList, TabsRoot, TabsTrigger } from "radix-vue";

const panels = ref([]);
const activePanel = ref("");
const actionsPortal = nanoid(4);
const emits = defineEmits(["update:activeTab"]);
watch(activePanel, (val) => {
  emits("update:activeTab", val);
});

provide(registerPanelInjectKey, (name) => {
  if (!panels.value.includes(name)) {
    panels.value.push(name);
  }

  nextTick(() => {
    if (!activePanel.value) {
      activePanel.value = name;
    }
  });
});
provide(actionsPortalInjectKey, actionsPortal);
</script>

<template>
  <TabsRoot v-model="activePanel">
    <BaseCard noPadding>
      <template #headerLeft>
        <TabsList class="space-x-6">
          <TabsTrigger v-for="name in panels" :key="name" :value="name" class="text-gray-400 data-[state=active]:text-gray-900">
            <h3 class="text-lg font-medium leading-6">
              {{ name }}
            </h3>
          </TabsTrigger>
        </TabsList>
      </template>
      <template #headerRight>
        <PortalTarget :name="actionsPortal" />
      </template>
      <template #default>
        <slot />
      </template>
    </BaseCard>
  </TabsRoot>
</template>
