<template>
  <Dialog :title="`Vælg konfliktløsning`" size="lg" confirm="Løs konflikter" :confirmDisabled="!resolution" @close="handleClose">
    <template #content>
      <div class="flex flex-col gap-2">
        <label
          v-if="Object.values(adjustedStayEvents.startTime).length > 0"
          class="block cursor-pointer rounded-md border p-6 hover:bg-gray-50"
          :class="resolution === 'adjustStayEventStartTimes' && 'border-blue-600 ring-1 ring-blue-600'"
        >
          <input id="adjustStayEventStartTimes" v-model="resolution" type="radio" name="resolution" value="adjustStayEventStartTimes" class="hidden" />
          <div class="flex">
            <div class="relative mr-4 inline-block">
              <div class="relative size-4 rounded bg-gray-300 ring-2 ring-white"></div>
              <div class="absolute left-1.5 top-1.5 size-4 rounded bg-red-500 ring-2 ring-white"></div>
            </div>
            <div>
              <p>Tilpas starttid på nye deltagelser</p>
              <span class="text-xs font-normal text-gray-500">
                <span v-for="(item, startTime) in adjustedStayEvents.startTime" :key="startTime">
                  Ændrer {{ item.uniqueStaysCount }} elevers starttid til {{ startTime.substring(0, 5) }} på {{ eventName }} {{ $filters.formatTime(eventStart) }} –
                  {{ $filters.formatTime(eventEnd) }} d. {{ $filters.formatDate(item.periodStart)
                  }}<span v-if="item.periodEnd">–{{ $filters.formatDate(item.periodEnd) }} ({{ item.uniqueEventsCount }} begivenheder)</span>
                </span>
              </span>
            </div>
          </div>
        </label>

        <label
          v-if="Object.values(adjustedStayEvents.endTime).length > 0"
          class="block cursor-pointer rounded-md border p-6 hover:bg-gray-50"
          :class="resolution === 'adjustStayEventEndTimes' && 'border-blue-600 ring-1 ring-blue-600'"
        >
          <input id="adjustStayEventEndTimes" v-model="resolution" type="radio" name="resolution" value="adjustStayEventEndTimes" class="hidden" />
          <div class="flex">
            <div class="relative mr-4 inline-block">
              <div class="relative size-4 rounded bg-gray-300 ring-2 ring-white"></div>
              <div class="absolute left-1.5 top-1.5 size-4 rounded bg-red-500 ring-2 ring-white"></div>
            </div>
            <div>
              <p>Tilpas sluttid på nye deltagelser</p>
              <span class="text-xs font-normal text-gray-500">
                <span v-for="(item, endTime) in adjustedStayEvents.endTime" :key="endTime">
                  Ændrer {{ item.uniqueStaysCount }} elevers sluttid til {{ endTime.substring(0, 5) }} på {{ eventName }} {{ $filters.formatTime(eventStart) }} –
                  {{ $filters.formatTime(eventEnd) }} d. {{ $filters.formatDate(item.periodStart) }}
                  <span v-if="item.periodEnd">–{{ $filters.formatDate(item.periodEnd) }} ({{ item.uniqueEventsCount }} begivenheder)</span>
                </span>
              </span>
            </div>
          </div>
        </label>

        <label
          v-if="Object.values(adjustedConflicts.startTime).length > 0"
          class="block cursor-pointer rounded-md border p-6 hover:bg-gray-50"
          :class="resolution === 'adjustConflictStartTimes' && 'border-blue-600 ring-1 ring-blue-600'"
        >
          <input id="adjustConflictStartTimeStartTimes" v-model="resolution" type="radio" name="resolution" value="adjustConflictStartTimes" class="hidden" />
          <div class="flex">
            <div class="relative mr-4 inline-block">
              <div class="relative z-10 size-4 rounded bg-gray-300 ring-2 ring-white"></div>
              <div class="absolute left-1.5 top-1.5 size-4 rounded bg-red-500 ring-2 ring-white"></div>
            </div>
            <div>
              <p>Tilpas starttid på blokerende deltagelser</p>
              <span class="text-xs font-normal text-gray-500">
                <span v-for="(item, startTime) in adjustedConflicts.startTime" :key="startTime">
                  Ændrer {{ item.uniqueStaysCount }} elevers starttid til {{ item.newStartTime.substring(0, 5) }} på {{ item.conflicts[0].event.name }}
                  {{ $filters.formatTime(item.conflicts[0].event.defaultStartTime) }} – {{ $filters.formatTime(item.conflicts[0].event.defaultEndTime) }} d.
                  {{ $filters.formatDate(item.periodStart)
                  }}<span v-if="item.periodEnd">–{{ $filters.formatDate(item.periodEnd) }} ({{ item.uniqueEventsCount }} begivenheder)</span>
                </span>
              </span>
            </div>
          </div>
        </label>

        <label
          v-if="Object.values(adjustedConflicts.endTime).length > 0"
          class="block cursor-pointer rounded-md border p-6 hover:bg-gray-50"
          :class="resolution === 'adjustConflictEndTimes' && 'border-blue-600 ring-1 ring-blue-600'"
        >
          <input id="adjustConflictEndTimes" v-model="resolution" type="radio" name="resolution" value="adjustConflictEndTimes" class="hidden" />
          <div class="flex">
            <div class="relative mr-4 inline-block">
              <div class="relative z-10 size-4 rounded bg-gray-300 ring-2 ring-white"></div>
              <div class="absolute left-1.5 top-1.5 size-4 rounded bg-red-500 ring-2 ring-white"></div>
            </div>
            <div>
              <p>Tilpas sluttid på blokerende deltagelser</p>
              <span class="text-xs font-normal text-gray-500">
                <span v-for="(item, endTime) in adjustedConflicts.endTime" :key="endTime">
                  Ændrer {{ item.uniqueStaysCount }} elevers sluttid til {{ item.newEndTime.substring(0, 5) }} på {{ item.conflicts[0].event.name }}
                  {{ $filters.formatTime(item.conflicts[0].event.defaultStartTime) }} – {{ $filters.formatTime(item.conflicts[0].event.defaultEndTime) }} d.
                  {{ $filters.formatDate(item.periodStart) }}
                  <span v-if="item.periodEnd">–{{ $filters.formatDate(item.periodEnd) }} ({{ item.uniqueEventsCount }} begivenheder)</span>
                </span>
              </span>
            </div>
          </div>
        </label>

        <label class="block cursor-pointer rounded-md border p-6 hover:bg-gray-50" :class="resolution === 'deleteStayEvents' && 'border-blue-600 ring-1 ring-blue-600'">
          <input id="deleteStayEvents" v-model="resolution" type="radio" name="resolution" value="deleteStayEvents" class="hidden" />
          <div class="flex">
            <div class="relative mr-4 inline-block">
              <div class="relative size-4 rounded border-2 border-dotted border-gray-300 ring-2 ring-white"></div>
              <div class="absolute left-1.5 top-1.5 size-4 rounded bg-red-500 ring-2 ring-white"></div>
            </div>
            <div>
              <p>Fjern nye deltagelser</p>
              <span class="text-xs font-normal text-gray-500">
                {{ uniqueNewStays.length }} elever fjernes fra {{ eventName }} {{ $filters.formatTime(eventStart) }} – {{ $filters.formatTime(eventEnd) }} d.
                {{ $filters.formatDate(events[0].defaultStartTime)
                }}<span v-if="events.length > 1">–{{ $filters.formatDate(events[events.length - 1].defaultStartTime) }} ({{ events.length }} begivenheder)</span>
              </span>
            </div>
          </div>
        </label>

        <label class="block cursor-pointer rounded-md border p-6 hover:bg-gray-50" :class="resolution === 'deleteConflicts' && 'border-blue-600 ring-1 ring-blue-600'">
          <input id="deleteConflicts" v-model="resolution" type="radio" name="resolution" value="deleteConflicts" class="hidden" />
          <div class="flex">
            <div class="relative mr-4 inline-block">
              <div class="relative z-10 size-4 rounded bg-gray-300 ring-2 ring-white"></div>
              <div class="absolute left-1.5 top-1.5 size-4 rounded border-2 border-dotted border-red-500 ring-2 ring-white"></div>
            </div>
            <div>
              <p>Fjern blokerende deltagelser</p>
              <span class="text-xs font-normal text-gray-500">
                {{ uniqueConflictStays.length }} elever fjernes fra
                <span v-for="(collection, key) in conflictEvents" :key>
                  {{ collection[0].name }} {{ $filters.formatTime(collection[0].defaultStartTime) }} – {{ $filters.formatTime(collection[0].defaultEndTime) }} d.
                  {{ $filters.formatDate(collection[0].defaultStartTime)
                  }}<span v-if="collection.length > 0"
                    >–{{ $filters.formatDate(collection[collection.length - 1].defaultStartTime) }} ({{ collection.length }} begivenheder)</span
                  >
                </span>
              </span>
            </div>
          </div>
        </label>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { groupBy } from "lodash";
import { uniqBy } from "lodash";
import Dialog from "~/components/dialogs/Dialog";
import { dayjs } from "~/utils/dayjs";

export default {
  components: { Dialog },

  props: {
    eventName: {
      type: String,
      required: true,
    },

    eventStart: {
      type: String,
      required: true,
    },

    eventEnd: {
      type: String,
      required: true,
    },

    conflictHexdigests: {
      type: Array,
      required: true,
    },

    stayEvents: {
      type: Array,
      required: true,
    },

    events: {
      type: Array,
      required: true,
    },
  },

  emits: ["close"],

  data() {
    return {
      resolution: null,
    };
  },

  computed: {
    conflicts() {
      return this.stayEvents.flatMap((se) => se.conflicts).filter((c) => this.conflictHexdigests.includes(c.hexdigest));
    },

    conflictEvents() {
      return groupBy(
        uniqBy(
          this.conflicts.map((c) => c.event),
          "hexdigest"
        ),
        "parentHexdigest"
      );
    },

    uniqueNewStays() {
      return uniqBy(
        this.stayEvents.map((se) => se.object),
        "id"
      );
    },

    uniqueConflictStays() {
      return uniqBy(
        this.conflicts.map((se) => se.stay),
        "id"
      );
    },

    adjustedStayEvents() {
      const results = {
        startTime: {},
        endTime: {},
      };

      this.conflicts.forEach((conflict) => {
        const stayEvent = this.stayEvents.find((se) => se.conflicts.some((c) => c.hexdigest === conflict.hexdigest));

        const stayEventStart = dayjs(stayEvent.startTime);
        const stayEventEnd = dayjs(stayEvent.endTime);
        const conflictStart = dayjs(conflict.startTime);
        const conflictEnd = dayjs(conflict.endTime);
        const isWrappingConflict = stayEventStart.isBefore(conflictStart) && stayEventEnd.isAfter(conflictEnd);
        const isStartWrappedByConflict = stayEventStart.isBetween(conflictStart, conflictEnd);
        const isEndWrappedByConflict = stayEventEnd.isBetween(conflictStart, conflictEnd);
        const canBeResolvedWithStartTimeChange = isWrappingConflict || (isStartWrappedByConflict && !isEndWrappedByConflict);
        const canBeResolvedWithEndTimeChange = isWrappingConflict || (isEndWrappedByConflict && !isStartWrappedByConflict);

        if (canBeResolvedWithStartTimeChange) {
          const item = (results.startTime[conflictEnd.toDate().toLocaleTimeString("da")] ||= { stayEvents: [] });
          item.stayEvents.push(stayEvent);
        }

        if (canBeResolvedWithEndTimeChange) {
          const item = (results.endTime[conflictStart.toDate().toLocaleTimeString("da")] ||= { stayEvents: [] });
          item.stayEvents.push(stayEvent);
        }
      });

      Object.values(results.startTime).forEach((item) => {
        const firstStayEvent = item.stayEvents[0];
        const lastStayEvent = item.stayEvents[item.stayEvents.length - 1];

        item.uniqueStaysCount = this.getUniqueStaysCount(item.stayEvents);
        item.uniqueEventsCount = uniqBy(
          this.events.filter((e) => e.stayEvents.some((se) => item.stayEvents.includes(se))),
          "hexdigest"
        ).length;
        item.periodStart = firstStayEvent.startTime;
        if (!dayjs(firstStayEvent.startTime).isSame(lastStayEvent, "day")) {
          item.periodEnd = lastStayEvent.startTime;
        }
      });
      Object.values(results.endTime).forEach((item) => {
        const firstStayEvent = item.stayEvents[0];
        const lastStayEvent = item.stayEvents[item.stayEvents.length - 1];

        item.uniqueStaysCount = this.getUniqueStaysCount(item.stayEvents);
        item.uniqueEventsCount = uniqBy(
          this.events.filter((e) => e.stayEvents.some((se) => item.stayEvents.includes(se))),
          "hexdigest"
        ).length;
        item.periodStart = firstStayEvent.startTime;
        if (!dayjs(firstStayEvent.startTime).isSame(lastStayEvent, "day")) {
          item.periodEnd = lastStayEvent.startTime;
        }
      });

      return results;
    },

    adjustedConflicts() {
      const results = {
        startTime: {},
        endTime: {},
      };

      this.conflicts.forEach((conflict) => {
        const stayEvent = this.stayEvents.find((se) => se.conflicts.some((c) => c.hexdigest === conflict.hexdigest));

        const stayEventStart = dayjs(stayEvent.startTime);
        const stayEventEnd = dayjs(stayEvent.endTime);
        const conflictStart = dayjs(conflict.startTime);
        const conflictEnd = dayjs(conflict.endTime);
        const isWrappingStayEvent = conflictStart.isBefore(stayEventStart) && conflictEnd.isAfter(stayEventEnd);
        const isStartWrappedByStayEvent = conflictStart.isBetween(stayEventStart, stayEventEnd);
        const isEndWrappedByStayEvent = conflictEnd.isBetween(stayEventStart, stayEventEnd);
        const canBeResolvedWithStartTimeChange = isWrappingStayEvent || (isStartWrappedByStayEvent && !isEndWrappedByStayEvent);
        const canBeResolvedWithEndTimeChange = isWrappingStayEvent || (isEndWrappedByStayEvent && !isStartWrappedByStayEvent);

        if (canBeResolvedWithStartTimeChange) {
          const item = (results.startTime[conflict.event.parentHexdigest] ||= {
            newStartTime: stayEventEnd.toDate().toLocaleTimeString("da"),
            conflicts: [],
          });
          item.conflicts.push(conflict);
        }

        if (canBeResolvedWithEndTimeChange) {
          const item = (results.endTime[conflict.event.parentHexdigest] ||= {
            newEndTime: stayEventStart.toDate().toLocaleTimeString("da"),
            conflicts: [],
          });
          item.conflicts.push(conflict);
        }
      });

      Object.values(results.startTime).forEach((item) => {
        const firstConflict = item.conflicts[0];
        const lastConflict = item.conflicts[item.conflicts.length - 1];

        item.uniqueStaysCount = this.getUniqueStaysCount(item.conflicts);
        item.uniqueEventsCount = uniqBy(item.conflicts, "event.hexdigest").length;
        item.periodStart = firstConflict.startTime;
        if (!dayjs(firstConflict.startTime).isSame(lastConflict, "day")) {
          item.periodEnd = lastConflict.startTime;
        }
      });
      Object.values(results.endTime).forEach((item) => {
        const firstStayEvent = item.conflicts[0];
        const lastStayEvent = item.conflicts[item.conflicts.length - 1];

        item.uniqueStaysCount = this.getUniqueStaysCount(item.conflicts);
        item.uniqueEventsCount = uniqBy(item.conflicts, "event.hexdigest").length;
        item.periodStart = firstStayEvent.startTime;
        if (!dayjs(firstStayEvent.startTime).isSame(lastStayEvent, "day")) {
          item.periodEnd = lastStayEvent.startTime;
        }
      });

      return results;
    },

    unAdjustableConflicts() {
      const eventStart = new Date(this.eventStart).toLocaleTimeString("da");
      const eventEnd = new Date(this.eventEnd).toLocaleTimeString("da");
      return this.conflicts.filter((c) => {
        const conflictStart = new Date(c.startTime).toLocaleTimeString("da");
        const conflictEnd = new Date(c.endTime).toLocaleTimeString("da");
        const isStartConflicting = conflictStart >= eventStart && conflictStart < eventEnd;
        const isEndConflicting = conflictEnd > eventStart && conflictEnd <= eventEnd;

        return isEndConflicting && isStartConflicting;
      });
    },
  },

  methods: {
    getUniqueStaysCount(collection) {
      return uniqBy(collection, (o) => o.object?.id || o.stay.id).length;
    },

    handleClose(confirmed) {
      if (!confirmed) {
        this.$emit("close", null);
      } else {
        const payload = {
          type: this.resolution,
        };

        switch (this.resolution) {
          case "adjustConflictStartTimes": {
            payload.type = "adjustConflicts";
            payload.conflicts = [];
            Object.values(this.adjustedConflicts.startTime).forEach(({ newStartTime, conflicts }) => {
              conflicts.forEach((c) => {
                payload.conflicts.push({
                  hexdigest: c.hexdigest,
                  newStartTime,
                });
              });
            });
            break;
          }
          case "adjustConflictEndTimes": {
            payload.type = "adjustConflicts";
            payload.conflicts = [];
            Object.values(this.adjustedConflicts.endTime).forEach(({ newEndTime, conflicts }) => {
              conflicts.forEach((c) => {
                payload.conflicts.push({
                  hexdigest: c.hexdigest,
                  newEndTime,
                });
              });
            });
            break;
          }
          case "adjustStayEventStartTimes": {
            payload.type = "adjustStayEvents";
            payload.stayEvents = [];
            Object.entries(this.adjustedStayEvents.startTime).forEach(([newStartTime, { stayEvents }]) => {
              stayEvents.forEach((se) => {
                payload.stayEvents.push({
                  hexdigest: se.hexdigest,
                  newStartTime,
                });
              });
            });
            break;
          }
          case "adjustStayEventEndTimes": {
            payload.type = "adjustStayEvents";
            payload.stayEvents = [];
            Object.entries(this.adjustedStayEvents.endTime).forEach(([newEndTime, { stayEvents }]) => {
              stayEvents.forEach((se) => {
                payload.stayEvents.push({
                  hexdigest: se.hexdigest,
                  newEndTime,
                });
              });
            });
            break;
          }
        }

        this.$emit("close", payload);
      }
    },
  },
};
</script>
