<template>
  <Dialog title="Send til e-Boks" confirm="Send lønsedler" data-testid="confirm-dialog" @close="onClose">
    <template #content>
      <p class="text-sm text-gray-700">Send alle lønsedler i denne lønkørsel til elevernes e-Boks</p>
      <div class="mt-6">
        <BaseCheckbox v-model="addText" label="Tilføj tekst til lønsedler" />
        <div v-if="addText" class="flex flex-col items-end">
          <BaseTextarea v-model="textToAdd" class="w-full" />
          <BaseButton icon="outline-external-link" size="sm" class="-mt-2" @click="openPreview"> Forhåndsvisning </BaseButton>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "~/components/dialogs/Dialog";
import { API_URL } from "~/constants/settings";

export default {
  components: { Dialog },

  emits: ["close"],

  data() {
    return {
      addText: false,
      textToAdd: "Dette er et udkast. Kig det grundigt igennem og kontakt administrationen hvis du har spørgsmål.",
    };
  },

  computed: {
    ...mapGetters("session", ["token"]),
  },

  methods: {
    openPreview() {
      window.open(`${API_URL}/${this.$route.params.fguInstitutionNumber}/pay_checks/1.pdf?token=${this.token}&overlay_text=${this.textToAdd}&disposition=inline`, "_blank");
    },

    onClose(accepted) {
      if (accepted) {
        this.$emit("close", this.addText ? this.textToAdd : true);
      } else {
        this.$emit("close", false);
      }
    },
  },
};
</script>
