import Honeybadger from "@honeybadger-io/js";

export const config = {
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
  revision: __FRONTEND_VERSION__, // eslint-disable-line no-undef
  environment: import.meta.env.MODE,
};

Honeybadger.beforeNotify((notice) => {
  if (
    notice.message?.includes("Failed to fetch dynamically imported module") ||
    notice.message?.includes("error loading dynamically imported module") ||
    notice.message?.includes("Importing a module script failed") ||
    notice.message?.includes("'text/html' is not a valid JavaScript MIME type")
  ) {
    return false;
  }
});

export const honeybadger = Honeybadger.configure(config);
