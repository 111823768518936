<script setup>
import { onMounted, provide, ref, watch } from "vue";
import { cloneDeep } from "lodash";
import { useRenderContext } from "~/composables/useRenderContext";

useRenderContext("form");

const emit = defineEmits(["submit", "change"]);
const props = defineProps({
  initialData: {
    type: Object,
    default: () => ({}),
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
});

provide("errors", props.errors);

const formData = ref(cloneDeep(props.initialData));
provide("formData", formData);
watch(
  formData,
  () => {
    emit("change", formData.value);
  },
  { deep: true }
);

const formRef = ref(null);
onMounted(() => {
  if (formRef.value && !formRef.value.contains(document.activeElement)) {
    formRef.value.querySelector("input, select, textarea")?.focus();
  }
});

function submit() {
  emit("submit", formData.value);
}
defineExpose({ submit });
</script>

<template>
  <form ref="formRef" @submit.prevent="submit">
    <slot />
  </form>
</template>
