import { inject, provide } from "vue";

/**
 * A composable that injects render context and optionally provides an additional render context to descendants.
 * @see withRenderContextMixin
 * @param {string=} providedRenderContext The render context, if any, to provide to descendants.
 */
export function useRenderContext(providedRenderContext) {
  const renderContext = inject("renderContext", []);

  provide("renderContext", providedRenderContext ? [...renderContext, providedRenderContext] : renderContext);

  return renderContext;
}
