<template>
  <div id="app">
    <router-view />
    <notifications width="384" animationName="anim-notification" :duration="4000" :position="'bottom right'">
      <template #body="{ item, close }">
        <Notification :title="item.title" :text="item.text" :type="item.type" :group="item.group" :data="item.data" :length="item.length" @close="close" />
      </template>
    </notifications>
  </div>
</template>

<script>
import { provide } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { mapGetters } from "vuex";
import Notification from "~/components/notifications/Notification";
import { SESSION_TOKEN } from "~/constants/settings";
import { store } from "~/store";
import { apolloClient } from "~/vue-apollo";

export default {
  components: {
    Notification,
  },

  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  computed: {
    ...mapGetters("session", ["status", "tokenData"]),
    fguInstitutionNumber() {
      return this.$route.params.fguInstitutionNumber;
    },
  },

  watch: {
    status() {
      const userId = this.tokenData?.user?.id;

      this.$honeybadger.setContext({ userId });
    },

    fguInstitutionNumber: {
      immediate: true,
      handler(fguInstitutionNumber) {
        this.$honeybadger.setContext({
          fguInstitutionNumber: parseInt(fguInstitutionNumber) || undefined,
        });
      },
    },
  },

  created() {
    window.addEventListener("storage", this.signOutAllTabs);
  },

  methods: {
    signOutAllTabs() {
      if (!localStorage.getItem(SESSION_TOKEN)) {
        store.dispatch("session/refreshSession");
      }
    },
  },
};
</script>
